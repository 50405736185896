@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* index.css */
@keyframes fadeOutBlur {
  to {
    opacity: 0;
    filter: blur(8px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    filter: blur(0px);
  }
}

.slide-exiting {
  animation: fadeOutBlur 500ms forwards;
}

.slide-entrance {
  animation: fadeIn 500ms forwards;
}

